#pricingTable {


  .table thead th {
    font-size: .8125rem;
    font-weight: 600;
    letter-spacing: .08em;
    text-transform: uppercase;
    color: var(--fixed-white);
    border-bottom-width: 1px;
    background-color: var(--primary-bg-color);
  }

  // .card-table thead th {
  //   border-top-width: 0;
  // }

  table thead tr {
    background-color: var(--primary-bg-color); //#eaeaea
  }

  .table tbody tr {
    background-color: var(--default-border);
  }

  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid var(--default-border);
    border-bottom-width: 2px;
  }

  .table-nowrap th,
  .table-nowrap td {
    white-space: nowrap;
  }

  .table th,
  .table td {
    padding: .9375rem;
    border-top: 1px solid var(--default-border);
    min-width: 110px !important;
    max-width: 140px;
  }
  .table th:first-child,
  .table td:first-child {
    
    max-width: 140px !important;
  }

  // .card-table td {
  //   overflow: hidden;

  // }

  .bg-light table td {
    border-top-color: var(--default-border);
  }



  .price-rules input {
    // min-width: 80px;
    //max-width: 110px;
    border: 0;
    border-bottom: 1px dashed var(--default-border);
    margin: auto;
  }

  .price-rules .standart-profit-margin {
    background-color: var(--white);
    // border-right: 1px solid var(--black);
    // border-left: 1px solid var(--black);
  }

  .price-rules .max-price-only-seller {
    background-color: var(--white);
    // border-right: 1px solid var(--black);
    // border-left: 1px solid var(--black);
  }

  .price-rules .default-shipping-cost {
    background-color: var(--white);
    // border-left: 1px solid var(--black);
  }

  .price-rules .shipping-template {
    // border-left: 2px solid var(--black);
    background-color: var(--white);
  }

  .price-rules .standart-profit-margin {
    background-color: var(--white);
    // border-right: 1px solid var(--black);
    // border-left: 1px solid var(--black);
  }

  .price-rules .max-price-only-seller {
    background-color: var(--white);
    // border-right: 1px solid var(--black);
    // border-left: 1px solid var(--black);
  }

  .price-rules .default-shipping-cost {
    background-color: var(--white);
    // border-left: 1px solid var(--black);
  }



  .price-rules .shipping-template {
    // border-left: 2px solid var(--black);
    background-color: var(--white);
    padding-right: 0 !important;
  }



  // .card-table thead th:first-child,
  // .card-table tbody td:first-child {
  //   padding-left: 1.5rem !important;
  //   // max-width: max-content !important;
  // }

  // .price-rules .standart-profit-margin-header {
  //   // border-right: 1px solid var(--black);
  //   // border-left: 1px solid var(--black);
  // }

  // .card-table thead th:last-child,
  // .card-table tbody td:last-child {
  //   padding-right: 1.5rem !important;
  // }
}