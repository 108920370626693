#warehouseMarketsDropDown {
  .main-header-right .dropdown .dropdown-menu {
    width: 95%;
    inset-inline-start: 0px;
    inset-inline-end: 5px;
    inset-block-start: 39px;
  }

  .dropdown {
    border-radius: 3px;
  }

  .dropdown.dropdown-menu.show {
    border: 1px solid var(--default-border);
    width: fit-content;
  }

  .dropdown:hover {
    background-color: var(--primary-bg-color);
    color: rgb(var(--white));
  }

}
