.blockOverlay {
    z-index: 1070;
    border-style: none;
    opacity: 50%;
    margin: 0em;
    padding: 0em;
    width: 100%;
    height: 100%;
    top: 0em;
    left: 0em;
    background-color: black;
    cursor: wait;
    position: fixed;
}

.loader {
    
    position: fixed;
    z-index: 1070;
    cursor: wait;
    width: 96px;
    height: 96px;

    top: calc(50% - 48px);
    left: calc(50% - 48px);
    /* border: 10px solid white;
    border-left: 10px solid #299dd7;
    border-radius: 50%; */
    /* animation: load8 1.1s infinite linear; */
    transition: opacity 0.3s;
}

.loader--hide {
    opacity: 0%;
}

@keyframes load8 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
