#modal-background {
    .no-pointer-events {
        pointer-events: none;
    }
    .modal-dialog-full-height {
        position: fixed !important;
        margin: auto !important;
        // width: 600px !important;
        min-width: 350px !important;
        height: 100% !important;
        right: 0px !important;
    }

    .modal-content-full-height {
        height: 100% !important;
    }

    .modal-dialog-full-height .modal-backdrop {
        display: none;
    }

}