.drnav {
    // border: 1px solid var(--default-border);
    background-color: rgb(var(--custom-white));
}

.drnav>.content,
.drnav>.actions {
    padding: 25px;
}

.drnav>.actions span {
    border-radius: 3px;
    cursor: pointer;
    user-select: none;
}

.drnav>.actions {
    padding: 0px;
    padding-top: 20px;
}

.drnav>.actions>ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
}

// .drnav > .actions > ul > li:last-child a {
//     background-color: var(--danger);
// }

.drnav>.actions span {
    display: block;
    background-color: rgb(var(--info-rgb));
    padding: 9px 25px;
    line-height: 1.573;
    color: var(--custom-white);
}

.drnav>.actions span:hover,
.drnav>.actions span:active {
    display: block;
    background-color: rgb(var(--info-rgb));
    padding: 9px 25px;
    line-height: 1.573;
    color: var(--custom-white);
}

.drnav>.actions .disabled span {
    background-color: var(--secondary);
    opacity: 0.8;
}

.drnav>.actions .disabled span:hover,
.drnav>.actions .disabled span:active {
    background-color: var(--secondary);
    opacity: 0.8;
}


.drnav>.steps .done span {
    color: var(--muted);
}