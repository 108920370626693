@import "../../../assets/style/variables.scss";

#pic-modal-background {

    .modal-display {
        display: block;
    }

    .modal-close-button {
        position: absolute;
        right: 5px;
        top: 5px;
    }

    .modal-close-button:hover {
        background-color: var(--default-border);
    }

    .modal-header {
        background-color: var(--primary-color);
    }

    .modal-title {
        color: var(--custom-white)
    }

    .modal-body {
        padding: 0px;
    }

    .swiper-button-next,
    .swiper-button-prev {
        color: var(--default-text-color) !important;
        // background-color: rgba(var(--info-rgb), 0.3);
        background-color: rgba(var(--gray-3), 0.3);
        background-color: var(--white-7);
    }

    .swiper-button-next:hover,
    .swiper-button-prev:hover {
        color: var(--custom-white) !important;
        // background-color: rgba(var(--info-rgb), 0.3);
        // background-color: rgba(var(--gray-2), 0.3);
        background-color: var(--white-9);
    }
}