.dr-input-content {
    position: relative;
}

.show-icon {
    display: block;
}

.hide-icon {
    display: none;
}

.form-control-has-icon-left {
    /* padding-left: 20px; */
    padding-right: 5px;
}

.form-control-has-icon-right {
    /* padding-left: 5px; */
    padding-right: 5px;
}

.has-icon {
    position: absolute;
    top: -2px;
    right: 0;
    z-index: 2;
    display: block;
    width: 14px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    color: var(--input-icon);
    margin: 0;
}

.has-icon-left {
    left: 3px;
}

.has-image {
    position: absolute;
    top: 0px;
    right: 0;
    z-index: 2;
    display: block;
    width: 100%;
    height: 34px;
    line-height: 34px;
    text-align: center;
    color: var(--input-icon);
    margin: 0;
}