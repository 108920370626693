.read-more-wrap{
    margin-bottom: 0;
    text-wrap: auto;
}

.read-more-state {
    display: none;
}

.read-more-target {
    opacity: 0;
    max-height: 0;
    font-size: 0;
    transition: 0.25s linear;
}

.read-more-dots {
    display: inline;
}

.read-more-state:checked ~ .read-more-wrap .read-more-dots {
    display: none;
}

.read-more-state:checked ~ .read-more-wrap .read-more-target {
    opacity: 1;
    font-size: inherit;
    max-height: 999em;
}

.read-more-state ~ .read-more-trigger:before {
    content: "See more";
}

.read-more-state:checked ~ .read-more-trigger:before {
    content: "See less";
}

.read-more-trigger {
    cursor: pointer;
    color: var(--info);
    font-weight:bold ;
}
