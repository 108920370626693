#profile-picture {
  .field {
    margin: 5px;
    display: flex;
    flex-direction: column;
  }

  input[type="file"] {
    display: none;
  }

  .custom-file-upload {
    // border-radius: 50%;
    border-radius: 3px;
    display: inline-block;
    position: relative;
    padding: 6px;
    cursor: pointer;
    // background: linear-gradient(90deg, var(--primary-bg-color), var(--teal));
    background-color: var(--default-border);
    margin-bottom: 25px;
  }

  .img-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    // border-radius: 50%;
    border-radius: 3px;

  }

  .img-upload:before {
    content: url("./../../assets/img/users/upload5.png");
    content: attr();
    font-size: 90px;
    position: absolute;
    padding-top: 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #63d3a6;
    width: 100%;
    height: 100%;
    // border-radius: 50%;
    border-radius: 3px;
    opacity: 0;
    transition: .5s ease;
    // background: linear-gradient(90deg, var(--primary-bg-color), var(--teal));
    background-color: var(--default-border);
  }

  .img-upload:hover:before {
    opacity: 1;
  }

  img {
    width: auto !important;
    height: 100% !important;
  }
}