.dr-dualdatepicker-content {
  position: relative;
  display: flex;
}

.rmdp-header {
  margin-top: 0px;
  padding: 0px 0;
}

.rmdp-header-plugin .col-6 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rmdp-header-plugin .row {
  height: 90px;
}

.rmdp-header-plugin {
  background-color: rgba(var(--teal-rgb));
  color: var(--custom-white);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  /* min-width: 420px; */
}

[data-theme-color=dark] .rmdp-header-plugin {
  color: rgba(var(--teal-rgb)) !important;
  border: 1px solid rgba(var(--teal-rgb));
  background-color: var(--menu-bg);
}



.rmdp-wrapper {
  background-color: var(--custom-white);
}

.rmdp-day,
.rmdp-week-day {
  color: var(--default-text-color);
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  background-color: rgba(var(--teal-rgb));
  color: var(--default-text-color);
}

.rmdp-header-values {
  color: var(--default-text-color);
}



.rmdp-arrow-container:hover {
  background-color: rgba(var(--teal-rgb));
  box-shadow: 0 0 3px #8798ad;
  color: var(--custom-white);
}

.rmdp-ep-shadow::after {
  background-color: rgba(var(--teal-rgb));
}

.rmdp-ep-arrow {
  border-color: rgba(var(--teal-rgb)) !important;
}

.rmdp-left i {
  border-color: var(--default-text-color);
}

.rmdp-right i {
  border-color: var(--default-text-color);
}

.rmdp-border-bottom {
  border-bottom: 1px solid rgba(var(--teal-rgb));
}

.rmdp-border-top {
  border-top: 1px solid rgba(var(--teal-rgb));
}

.rmdp-range {
  background-color: rgba(var(--teal-rgb));
  color: var(--custom-white);
}

.rmdp-day.rmdp-today span {
  background-color: rgb(var(--warning-rgb));
  color: var(--custom-white);
}

.rmdp-cancel {
  background-color: rgba(var(--teal-rgb));
  color: var(--custom-white);
}

.rmdp-cancel:hover {
  opacity: 0.7;
}

.rmdp-range-picker-footer .date-cancel:hover {
  opacity: 0.7;
}

.rmdp-range-picker-footer {
  display: flex;
  flex-direction: column;
}

.rmdp-range-picker-footer span {
  font-size: 0.875rem;
}

.rmdp-range-picker-footer h6 {
  font-size: 0.875rem;
  flex-direction: row;
  width: fit-content;
  margin-right: 10px;
}

.rmdp-range-picker-footer .rmdp-range-picker-footer-row {
  width: 100%;
}

.rmdp-container {
  width: 100% !important;
}

.rmdp-container .rmdp-input {
  display: block;
  width: 100% !important;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.45rem;
  padding-left: 30px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--default-text-color);
  background-color: rgb(var(--custom-white));
  background-clip: padding-box;
  border: 1px solid var(--default-border);
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
}

.rmdp-calender-icon {
  position: absolute;
  left: 10px;
  top: 11px;
  color: var(--input-icon);
  z-index: 9999;
}

.rmdp-calendar {
  margin: auto;
  margin-left: 1px;
  margin-right: 1px;
}

.rdmp-calender-disabled {
  pointer-events: none;
  color: var(--muted);
}

.rmdp-timeoptions {
  margin: auto;
  overflow: auto;
  max-height: 375px;
  /* padding-inline-start: 0.25rem !important;
  padding-inline-end: 0.25rem !important; */
  margin-top: 0.25rem !important;
}

@media (max-width: 480px) {
  .rmdp-day-picker {
    display: block !important;
  }
}