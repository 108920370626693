// .filepond--root {
//     max-height: 500px !important;
//     overflow: auto;
// }

@media (min-width: 30em) {
    .filepond--item {
        width: calc(50% - .5em);
    }
}

@media (min-width: 50em) {
    .filepond--item {
        width: calc(33.33% - .5em);
    }
}

.filepond--image-bitmap canvas {
    height:auto !important;
    width: 100% !important;
}

.filepond--image-bitmap {
    height: 100% !important;
    width: 100% !important;
}

// .filepond--image-clip {
//     height: 250px !important;
//     width: auto !important;
// }

// .filepond--image-preview {
//     height: 250px !important;
//     width: auto !important;
// }

.filepond--item {
    height: 200px !important;

}

.filepond--panel-center.filepond--item-panel {
    transform: none !important;

}

.filepond--panel-bottom.filepond--item-panel {
    transform: none !important;

}

.filepond--image-canvas-wrapper {
    transform: none !important;
    height: 100% !important;
    width: auto !important;
}