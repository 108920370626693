.chart-container {
  margin-bottom: 5rem;
  width: 100%;
  // height: 425px;
}



.apexcharts-inner {
  margin-top: 10px;
}

.apexcharts-canvas {
  width: auto !important;
}