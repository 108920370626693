@import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
@import "/public/assets/scss/_variables.scss";



.rrt-holder {
    // padding-top: 17px;
    // padding-left: 5px;
}

.rrt-text {
    white-space: break-spaces !important;
    position: absolute;
    top: 0;
    left: 80px;
}

.redux-toastr {

    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    .top-left,
    .top-right,
    .top-center,
    .bottom-left,
    .bottom-right,
    .bottom-center {
        width: 100%;
        position: absolute;
        z-index: 99999999;
        padding: 0 10;
    }

    .top-left,
    .top-right,
    .top-center {
        top: 0;
    }

    .top-right,
    .bottom-right {
        right: 0;
    }

    .bottom-left,
    .bottom-right,
    .bottom-center {
        bottom: 0;
    }

    .top-left,
    .bottom-left {
        left: 0;
    }

    .top-center,
    .bottom-center {
        left: 50%;
        //   $marginleft: math.div($toastr-width , 2);
        margin-left: -175px;
    }

    @media (max-width: 320px) {

        .top-left,
        .top-right,
        .top-center,
        .bottom-left,
        .bottom-right,
        .bottom-center {
            // width: 320px;
            width: 100%;
        }

        .top-center,
        .bottom-center {
            // margin-left: -160px;
            margin: auto;
        }

    }

    @media (max-width: 991.98px) {

        .top-left,
        .top-right,
        .top-center {
            top: 70px;
        }

        .toastr {
            .rrt-middle-container {

                min-height: 50px;
            }

            width: calc(100vw - 30px) !important;
        }

    }

    .toastr {
        background-color: #fcfcfc;
        width: calc(100vw - 300px);
        min-height: 30px;
        max-height: fit-content;
        overflow: hidden;
        margin: 5px;
        border-radius: 4px;
        position: fixed;
        z-index: 2;
        color: var(--custom-white);
        opacity: .94;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);

        &:hover:not(.rrt-message) {
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
            opacity: 1;
        }

        .toastr-status {
            width: 100%;
            height: 5px;

            &.success {
                background-color: rgb(var(--success-rgb));
            }

            &.warning {
                background-color: rgb(var(--warning-rgb));
            }

            &.info {
                background-color: var(--primary-color);
            }

            &.error {
                background-color: rgb(var(--danger-rgb));
            }
        }

        .rrt-left-container,
        .rrt-right-container {
            float: left;
            text-align: center;
            overflow: hidden;
        }

        .rrt-left-container {
            width: min-content;
            top: 0;
            left: 0;
            position: unset;
            bottom: 0;
            height: fit-content;

            .rrt-holder {
                width: 35px;
                // height: 24px;
                position: absolute;
                top: 0;
                margin-top: 0px;
                left: 5px;
                line-height: 16px;
                height: min-content;
                margin: auto;
                padding: 5px;
            }

            .toastr-icon {
                fill: var(--custom-white);
                vertical-align: middle;
                width: 16px !important;
                height: 16px !important;
                margin: auto;
            }
        }

        .rrt-middle-container {
            width: 65%;
            // height: 70px;

            position: relative;
            float: left;
            // font-family: Arial, Helvetica, sans-serif, sans-serif;
            font-size: 1em;
            text-align: left;
            padding: 10px 5px;
            vertical-align: middle;
            margin: 3px 0px 10px 50px;

            .rrt-title {
                font-size: 1.1em;
                font-weight: bold;
                margin-bottom: 5px;
                position: absolute;
                top: 0;
                left: 0;
                max-width: 80px;
            }
        }

        .rrt-right-container {
            width: 10%;
        }
   
        .close-toastr {
            width: 35px;
            height: max-content;
            position: absolute;
            top: 15px;
            right: 0;
            background-color: transparent;
            font-size: 22px;
            border: none;
            outline: none;
            opacity: 0.5;
            cursor: pointer;
            // font-family: "Helvetica Neue", Helvetica, Arial sans-serif;
            color: var(--custom-white);

            &:hover {
                opacity: 1;
            }

            &:focus {
                outline: none;
            }

            span {
                position: absolute;
                left: 0;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        &.rrt-info,
        &.rrt-success,
        &.rrt-warning,
        &.rrt-error {
            color: var(--custom-white);
        }

        &.rrt-info {
            background-color: rgba(var(--info-rgb), 0.6);

            .rrt-progressbar {
                background-color: rgb(var(--info-rgb));
            }
        }

        &.rrt-success {
            background-color: rgba(var(--success-rgb), 0.6);

            .rrt-progressbar {
                background-color: rgb(var(--success-rgb));
            }
        }

        &.rrt-warning {
            background-color: rgba(var(--warning-rgb), 0.6);

            .rrt-progressbar {
                background-color: rgb(var(--warning-rgb));
            }
        }

        &.rrt-error {
            background-color: rgba(var(--danger-rgb), 0.6);

            .rrt-progressbar {
                background-color: rgb(var(--danger-rgb));
            }
        }

        &.rrt-light {
            .rrt-progressbar {
                background-color: rgb(var(--light-rgb));
            }

            .toastr-icon {
                fill: #333 !important;
            }
        }



        &.rrt-message {
            opacity: 1;
            border: 1px solid var(--default-border);

            .rrt-title {
                width: 90%;
                height: 50px;
                text-align: center;
                overflow: hidden;
                font-size: 1.2em;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                line-height: 50px;
                padding: 0 20px;
            }

            .rrt-text {
                width: 100%;
                max-height: 400px;
                overflow: hidden;
                overflow-y: auto;
                border-top: 1px solid #f1f1f1;
                border-bottom: 1px solid #f1f1f1;
                background-color: rgb(var(--light-rgb));
                padding: 15px;
                font-size: 1.1em;
                margin-bottom: 20px;



                img {
                    display: block;
                    margin: 10px auto;
                    max-width: 100%;
                }
            }

            .close-toastr {
                height: 50px;
            }
        }

        .rrt-progress-container {
            height: 5px;
            margin: 0 -20px -20px -60px;
            position: absolute;
            bottom: 20px;
            width: 100%;

            .rrt-progressbar {
                -webkit-border-radius: 0 0 0 4px;
                -moz-border-radius: 0 0 0 4px;
                border-radius: 0 0 0 4px;
                height: 100%;
            }
        }
    }

    .toastr-attention {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 1;
    }
}