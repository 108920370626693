.modal-dialog {
    max-width: 1072px;
    margin-right: auto;
    margin-left: auto;

}


.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-dialog-centered .modal-content {
    min-height: 300px !important;
}


// .modal-dialog-full-height {
//     position: fixed !important;
//     margin: auto !important;
//     // width: 600px !important;
//     min-width: 350px !important;
//     height: 100% !important;
//     right: 0px !important;
// }

// .modal-content-full-height {
//     height: 100% !important;
// }

// .modal-dialog-full-height .modal-backdrop {
//     display: none;
// }