.dr-tooltip {
    position: relative;
    display: inline-block;
    top: 3px;
    left: 5px;
}
.dr-tooltip:hover {
    cursor: help;
}
.dr-tooltip.left-0 {
    left: 0px !important;
}

.dr-tooltip .dr-tooltiptext {
    display: none;
    width: fit-content;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 7px 5px 7px;
    border-radius: 6px;
    position: absolute;
    z-index: 2;
    top: -5px;
    left: 20px;
}

/* Show the dr-tooltip text when you mouse over the dr-tooltip container */
.dr-tooltip:hover .dr-tooltiptext {
    display: block;
    position: fixed;
    width: auto;
    max-width: 250px;
}
