@media (max-width: 1750px) {


    .timeline.timeline-sm .timeline-body {
        width: 100%;
    }

    .timeline.timeline-sm .timeline-icon a {
        display: none;
    }

    .timeline.timeline-sm .timeline-icon i {
        display: none;
    }

    .timeline.timeline-sm li:before {
        display: none;
    }

    .timeline.timeline-sm .body .media {
        flex-direction: column;
    }

    .timeline.timeline-sm .body .media .main-img-user {
        margin-block-end: 0.625rem !important;
    }

    .timeline.timeline-sm .timeline-time {
        z-index: 99;
        width: 100%;
        inset-inline-end: 0.313rem !important;
        position: absolute;
        inset-block-start: 3.8rem !important;
    }

    .timeline.timeline-sm .main-content {
        flex-direction: column;
    }

    .timeline.timeline-sm .timeline-body {
        margin-inline-start: 0;
        margin-inline-end: 0;
        position: relative;
    }

    .timeline.timeline-sm .timeline-time .date,
    .timeline.timeline-sm .timeline-time .time {
        display: inline;
    }

    .timeline.timeline-sm .timeline-time .time {
        line-height: 1rem;
        font-size: 0.688rem;
        margin-inline-start: -4.687rem;
        margin-inline-end: 0.625rem;
        margin-block-end: 43px;
        top: -35px;
        position: absolute;
    }

    .timeline-center.timeline-sm .timeline-center-body p,
    .timeline-center.timeline-sm .timeline-center-body-right p {
        font-size: 11px;
    }

}