#main-header {
  .main-logo img {
    height: 1.5rem;
  }

  .side-menu__item {
    position: relative;
    padding: 10px;
    height: 50px;
    font-size: 15px;
    display: flex;
    align-items: center;
    margin: 0.25rem !important;
    color: var(--color);
  }

  .navbar-nav {
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
  }

  .main-header-left {
    min-width: max-content;
  }

  .main-header-middle {
    display: flex;
    align-items: center;
    width: inherit;
  }

  .main-header-right {
    min-width: max-content;
  }

  @media (max-width: 1549px) {
    .responsive2-navbar.navbar .navbar-collapse {
      width: 100%;
      background: rgb(var(--white));
      margin-block-start: 0;
      z-index: 999;
      //inset-block-start: 64px;
      border-block-end: 1px solid var(--default-border);
      border-block-start: 1px solid var(--default-border);
      position: fixed;
      inset-inline-start: 0;
      inset-inline-end: 0;
      transition: inset-block-start 0.3s;

    }
  }

  @media (min-width: 1550px) {
    .navbar-expand-lg.responsive2-navbar .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
      background: transparent;
    }
  }

  @media (max-width: 991px) {
    .responsive2-navbar-inset-start-big {
      inset-block-start: 114px;
    }
  }

  @media (min-width: 992px) {
    .responsive2-navbar-inset-start-big {
      inset-block-start: 64px;
    }
  }

  .responsive2-navbar-inset-start-small {
    inset-block-start: 64px;
  }


}