#pageFilters {
    .table {
        border: 1px solid var(--default-border);
    }

    .table {
        width: 100% !important;
        border-top: 1px solid #cacaca;
    }

    div.dataTables_scrollHead .table {
        margin-block-end: 0 !important;
        margin-block-start: 0 !important;
    }


    tr.selected {
        background-color: var(--default-border) !important;
    }

    .table thead tr {
        // background-color: var(--default-border) !important;
        // color: var(--primary-bg-color);
        color: var(--color) !important;
        background-color: var(--custom-white) !important;
    }

    .table thead th,
    .table thead td {
        // color: var(--primary-bg-color) !important;
        color: var(--color) !important;
        text-align: center !important;
        // border-block-start-width: 0;
        // border-block-end-width: 0;
        // padding: 8px 10px;
        font-weight: 700 !important;
        font-size: 12px !important;
        letter-spacing: 0.5px !important;
        text-transform: uppercase;
        padding-block-start: 15px !important;
        padding-inline-end: 15px !important;
        padding-block-end: 15px !important;
        padding-inline-start: 15px !important;

    }

    .table thead th:first-child,
    .table thead td:first-child {
        // color: var(--primary-bg-color) !important;
        color: var(--color) !important;
        text-align: start !important;
    }

}