// .customLook {
//   --tag-bg: #0052BF;
//   --tag-hover: #CE0078;
//   --tag-text-color: #FFF;
//   --tags-border-color: silver;
//   --tag-border-radius: 25px;
//   --tag-text-color--edit: #111;
//   --tag-remove-bg: var(--tag-hover);
//   --tag-pad: .6em 1em;
//   --tag-inset-shadow-size: 1.35em;
//   --tag-remove-btn-bg--hover: black;

//   display: inline-block;
//   min-width: 0;
//   border: none;
// }

// .customLook .tagify__tag {
//   margin-top: 0;
// }

// /* Do not show the "remove tag" (x) button when only a single tag remains */
// .customLook .tagify__tag:only-of-type .tagify__tag__removeBtn {
//   display: none;
// }

// .customLook .tagify__tag__removeBtn {
//   opacity: 0;
//   transform: translateX(-6px) scale(.5);
//   margin-left: -3ch;
//   transition: .12s;
// }

// .customLook .tags-input-control .tagify__tag:hover .tagify__tag__removeBtn {
//   transform: none;
//   opacity: 1;
//   margin-left: -1ch;
//   // color: red;
// }

// .customLook+button {
//   color: #0052BF;
//   font: bold 1.4em/1.65 Arial;
//   border: 0;
//   background: none;
//   box-shadow: 0 0 0 2px inset currentColor;
//   border-radius: 50%;
//   width: 1.65em;
//   height: 1.65em;
//   cursor: pointer;
//   outline: none;
//   transition: .1s ease-out;
//   margin: 0 0 0 5px;
//   vertical-align: top;
// }

// .customLook+button:hover {
//   box-shadow: 0 0 0 5px inset currentColor;
// }

// .customLook .tagify__input {
//   display: none;
// }

.tags-input-control {
  display: block;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--color);
  background-color: rgb(var(--white));
  background-clip: padding-box;
  border: 1px solid var(--default-border);
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.tags-input-control-invalid {
  display: block;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--color);
  background-color: rgb(var(--white));
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  border: 1px solid var(--bs-form-invalid-border-color);
  border-radius: 3px;
}

.tags-input-control-valid {
  display: block;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--color);
  background-color: rgb(var(--white));
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  padding-right: calc(1.5em + .75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border: 1px solid var(--bs-form-valid-border-color);
  border-radius: 3px;
}


.tagify__tag {
  display: inline-flex;
  align-items: center;
  margin: 5px 0 5px 5px;
  position: relative;
  z-index: 1;
  outline: 0;
  line-height: normal;
  cursor: default;
  transition: .13s ease-out;
  // width: 120px;
  width: auto;
  background-color: rgba(var(--success-rgb), 0.1) !important;
  border-radius: 4px;
  // box-shadow: var(--bs-box-shadow) !important;
  border: 1px solid var(--default-border);
}

.tagify__tag > div {
  vertical-align: top;
  box-sizing: border-box;
  max-width: 100%;
  padding: var(--tag-pad);
  color: var(--tag-text-color);
  line-height: inherit;
  border-radius: var(--tag-border-radius);
  white-space: nowrap;
  transition: .13s ease-out;
  // background-color: beige;
}

.tagify__tag__removeBtn {
  order: 5;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  cursor: pointer;
  font: 14px/1 Arial;
  background: var(--tag-remove-btn-bg);
  color: var(--tag-remove-btn-color);
  width: 14px;
  height: 14px;
  margin-right: 4.6666666667px;
  margin-left: auto;
  overflow: hidden;
  transition: .2s ease-out;
}

.tagify__tag__removeBtn:hover+div::before {
  box-shadow:0 0 0 2.2em var(--tag-remove-bg,rgba(211,148,148,.3)) inset!important;
  transition:box-shadow .2s
}

.tagify__tag>div::before {
  content:"";
  position:absolute;
  border-radius:inherit;
  inset:var(--tag-bg-inset,0);
  z-index:-1;
  pointer-events:none;
  transition:120ms ease;
  animation:tags--bump .3s ease-out 1;
  // box-shadow:0 0 0 var(--tag-inset-shadow-size) var(--tag-bg) inset
  box-shadow:none
}

.tagify {
  --tags-disabled-bg: #F1F1F1;
  --tags-border-color: #DDD;
  --tags-hover-border-color:var(--color);
  --tags-focus-border-color: var(--default-border);
  --tag-border-radius: 3px;
  --tag-bg: var(--default-border); //#E5E5E5;
  --tag-hover: var(--primary-bg-color);
  --tag-text-color: var(--color);
  --tag-text-color--edit: var(--color);
  --tag-pad: 0.3em 0.5em;
  --tag-inset-shadow-size: 1.1em;
  --tag-invalid-color: #D39494;
  --tag-invalid-bg: rgba(211, 148, 148, 0.5);
  --tag-remove-bg: rgba(211, 148, 148, 0.3);
  --tag-remove-btn-color: var(--color);
  --tag-remove-btn-bg: none;
  --tag-remove-btn-bg--hover: #c77777;
  --input-color: inherit;
  --tag--min-width: 1ch;
  --tag--max-width: auto;
  --tag-hide-transition: 0.3s;
  --placeholder-color: rgba(0, 0, 0, 0.4);
  --placeholder-color-focus: rgba(0, 0, 0, 0.25);
  --loader-size: .8em;
  --readonly-striped: 1;
  line-height: 0;
  cursor: text;
}

